import { Typography, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import Layout from '../../components/layout'

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(5),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        gap: 20,
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2),
        }
    },

}))

const KVKK_EN = ({ location }) => {
    const classes = useStyles()

    return (<Layout nodeLocale='en-EU' location={location} description='Personal Data Processing and Protection Statement'>
        <Grid container className={classes.root}>
            <Typography component='h1' variant='h3'>Protection of Personal Data</Typography>
            <Grid item>
                <Typography variant='body1'>
                    We, as Atlaspet Pet Ürünleri Pazarlama ve Ticaret A.Ş. (“Atlaspet”), are showing maximum care and sensitivity for the security of your personal data.
                </Typography>
                <Typography variant='body1'>
                    We are also attaching great importance to make sure that all kinds of personal data of all and any people related to Atlaspet, including, but not limited to, the people making use of the products and services of Atlaspet, are duly processed and kept in strict compliance with the Law No. 6698 on the Protection of Personal Data (the “Law”). Being fully conscious of our liability, and in accordance with the Law, we, acting as a “Data Controller”, are processing your personal data and information within the following scope and in accordance with the following terms and conditions.
                    For detailed information on the methods and purposes for the processing of your personal data by Atlaspet, please visit Atlaspet's Privacy Notice and Cookie Notice.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h5'>
                    What is Personal Data?
                </Typography>
                <Typography variant='body2'>
                    Personal data is defined in the Law as all kinds of information and data regarding an identified or identifiable natural person. Accordingly, your name, surname, date of birth, identity number, electronic mail address, phone number etc. shared by you with us are named and termed as Personal Data for the purposes hereof.
                </Typography>
                <Typography variant='body2'>
                    Atlaspet collects your personal data when you share it directly with Atlaspet, through automatic means or other sources, such as third-party platforms.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h5'>
                    What are the purposes and legal bases of processing of your personal data?
                </Typography>
                <Typography variant='body2'>
                    Atlaspet is collecting and processing your personal data solely in order to perform and fulfill its legal obligations relating to your product and service relations with Atlaspet and to keep you informed about the campaigns and advantages pertaining thereto.
                </Typography>
                <Typography variant='body2'>
                    Accordingly, Atlaspet collects and processes your personal data for the purpose to evaluate and process any complaints, to process and manage your payments, improve our products, services, communication methods and functioning of our websites, and to provide you with personalized products, communication contents and targeted advertisements as well as product recommendations.
                </Typography>
                <Typography variant="body2">
                    For the purposes set out above, Atlaspet processes your personal data based on your explicit consent, or without your consent where the processing is necessary for the establishment or performance of our agreement with you, or to the extent our legitimate interests require, provided that it does not harm your fundamental rights and freedoms.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h5'>
                    To whom are we transferring and disclosing your personal data?
                </Typography>
                <Typography variant="body2">
                    We may share your personal data with our shareholders, or our direct / indirect local / foreign subsidiaries, or local or foreign real and legal persons with whom we cooperate for performance of our activities and operations (such as cargo, transport, call center, software, or cloud services) and/or representing us throughout the continuity of our business relations.
                    Furthermore, your Personal Data may also be shared by us with competent courts and other governmental bodies and public entities for the performance of our legal duties and obligations. Atlaspet may also share your personal data and traffic information in connection with your visit to Atlaspet websites to ensure your security and to fulfill Atlaspet's obligations under the applicable legislation with the authorities and institutions.
                </Typography>
            </Grid>
            <Grid>
                <Typography variant='h5'>
                    How are we storing and keeping your personal data?
                </Typography>
                <Typography variant="body2">
                    Your personal data shared with Atlaspet is being kept and stored in Atlaspet’s local / foreign secure servers in strict compliance with the applicable laws and regulations, the provisions of the Law, and Atlaspet standards relating thereto. Atlaspet takes the necessary technical and administrative security measures specified under the legislation to protect the security of your personal data.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h5'>

                    How long do we keep your personal data for?
                </Typography>
                <Typography variant="body2">
                    In accordance with Article 7/f.1. of the Law, once the purposes for processing the data no longer exist and/or upon expiration of the legal retention periods during which we are obliged to retain your personal data, your personal data will be deleted, destroyed or anonymized.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h5'>
                    What are your rights arising out of the Law?
                </Typography>
                <Typography variant="body2">
                    In accordance with Article 11 of the Law, to the extent Atlaspet is processing your data as a data controller, you have the following rights:
                </Typography>
                <ul>
                    <li>learn whether any of your personal data is subject to processing;</li>
                    <li>request information regarding the processing activities;</li>
                    <li>learn the purposes for processing;</li>
                    <li>know local and foreign third persons if, and to whom, personal data are transferred;</li>
                    <li>to request correction of personal data if processed incompletely or inaccurately;</li>
                    <li>request deletion or destruction of personal data if the purposes for processing no longer exist;</li>
                    <li>request notification of deletion or correction to third parties to whom personal data is transferred;</li>
                    <li>object to negative consequences which arise as a result of processing of personal data by automatic means; and
                        request indemnification if you suffer damage as a result of illegal processing of your personal data.
                    </li>
                </ul>
                <Typography variant='body2'>The requests found in your application will be resolved as soon as possible according to the nature of your request and within thirty days at the latest.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h5'>
                    How can you exercise your rights relating to your personal data?
                </Typography>
                <Typography variant="body2">
                    You can exercise your rights listed under Article 11 of the Law as described above by contacting us through the communication form available at https://www.Atlaspet.com.tr/en/contact/ or by calling Atlaspet consumer line 0212-807 01 40.
                </Typography>
            </Grid>


        </Grid>
    </Layout>)
}
export default KVKK_EN